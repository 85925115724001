<template>
  <div class="app-login">
    <div id="banner" class="col-md-7 col-sd-12">
      <div>
        <img width="250px" src="@/assets/img/insideBrasil.png" alt="" />
      </div>
      <div>
        <video
          autoplay
          class="video-background"
          src="https://s3-sa-east-1.amazonaws.com/ilumeo-brasil-com-br/Inside_Brasil.mp4"
        ></video>
      </div>
    </div>
    <div class="mt-10 col-md-4 col-sd-12">
      <div class="heading">
        <h5>Alterar Senha</h5>
      </div>
      <div class="form">
        <text-field
          id="password"
          label="Senha"
          v-model="user.password"
          type="password"
          :value="user.password"
          placeholder="Senha"
          :textFieldError="errorPass"
        />
        <custom-button
          color="primary"
          @click="sendPass()"
          :loading="loading"
          text="Confirmar"
        />

        <custom-button
          color="primary"
          @click="$router.push({ name: 'login' })"
          text="Cancelar"
          outlined
        />
      </div>
    </div>
    <custom-modal v-model="show" @confirm="confirm" @cancel="cancel">
      <span slot="title">
        <span class="title text-center">Senha alterada com sucesso!</span>
      </span>
      <div slot="content" class="text-center">
        <span class="content-modal">
          Pronto, agora você pode acessar o sistema com sua nova senha.
        </span>
      </div>
      <div slot="actions" class="d-flex text-center">
        <custom-button icon color="primary" @click="confirm" text="Confirmar"
          ><div slot="icon">
            <span class="material-icons material-icons-outlined">
              arrow_forward
            </span>
          </div></custom-button
        >
      </div>
    </custom-modal>
  </div>
</template>

<script>
import auth from "@/api/services/auth";
import CustomButton from "@/components/Button";
import TextField from "@/components/Form/TextField/";
import CustomModal from "@/components/Modal/";
export default {
  components: {
    CustomButton,
    CustomModal,
    TextField,
  },
  data: () => ({
    user: {
      password: "",
    },
    loading: false,
    errorPass: null,
    show: false,
  }),
  methods: {
    async sendPass() {
      this.loading = true;
      try {
        const response = await auth.sendPass(
          this.user,
          this.$route.params.token
        );
        if (response.status === 200) {
          this.show = true;
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.errorPass = "Você não tem permissão para alterar a senha.";
      }
    },
    confirm() {
      // some code...
      this.show = false;
      this.$router.push({ name: "login" });
    },
    cancel(close) {
      // some code...
      close();
    },
  },
};
</script>

<style scoped>
.app-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0;
  margin: 0;
  background: #e7e7e7;
}
#banner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #221e1e;
  padding: 0px 25px;
  border: 0;
}
#login {
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0d0c22;
}
.heading {
  margin-bottom: 10px;
  color: #0d0c22;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 1.2rem;
}

.video-background {
  width: 100%;
  height: 100%;
}
.form {
  display: flex;
  width: 95%;
  padding: 0px 0px;
  flex-direction: column;
}
.title {
  padding: 10px 0px;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}
.footer-login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 95%;
}
@media only screen and (max-width: 768px) {
  .app-login {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 0;
    margin: 0;
    background: #e7e7e7;
  }
  #banner {
    /* display: none; */
  }
  #login {
    padding: 2em 0.55em;
  }
}
</style>
